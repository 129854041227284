import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const menuItems = [
  { href: "/account/dashboard", label: "Account Dashboard" },
  { href: "/account/my-orders", label: "My Orders" },
  { href: "/account/mywishlist", label: "My Wishlist" },
  { href: "/account/history", label: "Customisation History" },
  {
    href: "/account/mygiftcards",
    label: "My Gift Cards",
  },
  {
    href: "/account/newslettersubscriptions",
    label: "Newsletter Subscriptions",
  },
];

export const transformText = (text, type) => {
  switch (type) {
    case "uppercase":
      return text.toUpperCase();
    case "lowercase":
      return text.toLowerCase();
    case "capitalize":
      return text
        ?.split(" ")
        ?.map(
          (word) =>
            word?.charAt(0).toUpperCase() + word?.slice(1)?.toLowerCase()
        )
        ?.join(" ");
    case "first-letter":
      return text.charAt(0).toUpperCase() + text?.slice(1)?.toLowerCase();
    default:
      return text;
  }
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid CORS issues
    image.src = url;
  });

export const getRadianAngle = (degreeValue) => {
  return (degreeValue * Math.PI) / 180;
};

export const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        console.error("Canvas is empty");
        return;
      }
      blob.name = "cropped.jpg";
      window.URL.revokeObjectURL(imageSrc);
      const fileUrl = window.URL.createObjectURL(blob);
      resolve(fileUrl);
    }, "image/jpeg");
  });
};

export const shuffleArray = (array) => {
  const shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[randomIndex]] = [
      shuffledArray[randomIndex],
      shuffledArray[i],
    ];
  }
  return shuffledArray;
};

// {
//     "name": "India",
//     "dialCode": "91",
//     "countryCode": "in",
//     "format": "+.. .....-....."
// }

export const validatePhoneFormat = (phoneNumber, country) => {
  // console.log(phoneNumber, country);
  if (!country || !phoneNumber) return;

  // Transform the format string into a regex pattern
  // Replace dots with \d for digits and handle optional spaces
  const pattern = country.format
    .replace(/\./g, "\\d") // Replace '.' with '\d'
    .replace(/\+/g, "") // Remove '+' from pattern
    .replace(/-/g, "") // Remove '-' from pattern
    .replace(/\(/g, "") // Replace '(' with ''
    .replace(/\)/g, "") // Replace ')' with ''
    .replace(/\s+/g, ""); // Remove spaces from pattern

  // Create the regex pattern
  const regex = new RegExp(`^${pattern}$`);

  // Debugging
  // console.log(`Checking ${phoneNumber} against ${regex}`);

  // Validate the phone number
  return regex.test(phoneNumber);
};
